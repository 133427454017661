import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { firebaseService } from '../services/firebaseService';
import stripeService from '../services/stripeService';

type SubscriptionPlanType = 'basic' | 'pro' | 'enterprise';
type SubscriptionStatus = 'active' | 'canceled' | 'past_due' | 'unpaid' | 'incomplete' | 'loading' | 'none' | 'trialing';

interface SubscriptionData {
  planType: SubscriptionPlanType;
  status: SubscriptionStatus;
  currentPeriodEnd: number | null;
  cancelAtPeriodEnd: boolean;
  stripeCustomerId: string;
  stripeSubscriptionId: string | null;
  updatedAt?: any;
  canceledAt?: any;
  willDowngradeTo?: SubscriptionPlanType | null;
  trialEndDate?: number | null; // Aktualisiert für Trail-Funktionalität
}

interface SubscriptionContextType {
  subscriptionPlan: SubscriptionPlanType;
  subscriptionStatus: SubscriptionStatus;
  currentPeriodEnd: number | null;
  cancelAtPeriodEnd: boolean;
  loadingSubscription: boolean;
  canceledAt: any | null;
  willDowngradeTo: SubscriptionPlanType | null;
  isSubscriptionActive: () => boolean;
  hasPaidSubscription: () => boolean;
  canAccessProFeatures: () => boolean;
  canAccessEnterpriseFeatures: () => boolean;
  hasActiveOrPendingCancellationSubscription: () => boolean;
  redirectToCheckout: (planType: 'pro' | 'enterprise', isTrial?: boolean) => Promise<void>;
  redirectToCustomerPortal: () => Promise<void>;
  renewSubscription: () => Promise<void>;
  fetchUserSubscription: (userId: string) => Promise<void>;
  isSubscriptionInfoLoaded: boolean;
  subscription: SubscriptionData | null;
  isTrialEligible: boolean;
  checkTrialEligibility: () => Promise<boolean>;
  isInTrialPeriod: () => boolean;
  trialEndDate: number | null;
  daysLeftInTrial: number | null;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};

export function SubscriptionProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuth();
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlanType>('basic');
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>('loading');
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<number | null>(null);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState<boolean>(false);
  const [loadingSubscription, setLoadingSubscription] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<SubscriptionData | null>(null);
  const [canceledAt, setCanceledAt] = useState<any | null>(null);
  const [willDowngradeTo, setWillDowngradeTo] = useState<SubscriptionPlanType | null>(null);
  const [isTrialEligible, setIsTrialEligible] = useState<boolean>(false);
  const [trialEndDate, setTrialEndDate] = useState<number | null>(null);
  const [daysLeftInTrial, setDaysLeftInTrial] = useState<number | null>(null);

  // Normalize subscription data to handle trial cancellations correctly
  const normalizeSubscriptionData = (firestoreSubscription: any) => {
    // Create a copy to avoid mutating the original
    const normalizedSubscription = { ...firestoreSubscription };
    
    // Special handling for cancelled trials
    // When a trial is cancelled, Firebase sets planType to 'basic' but keeps status 'trialing'
    // We need to override this and treat it as 'pro' until trial ends
    if (normalizedSubscription.status === 'trialing' && 
        normalizedSubscription.cancelAtPeriodEnd === true &&
        normalizedSubscription.trialEndDate && 
        normalizedSubscription.trialEndDate > Date.now() / 1000 &&
        normalizedSubscription.planType === 'basic') {
      
      // Override to Pro for cancelled trials
      normalizedSubscription.planType = 'pro';
      
      // Log this change in development
      if (process.env.NODE_ENV === 'development') {
        console.log('Normalized cancelled trial subscription: Changed planType from basic to pro');
      }
    }
    
    return normalizedSubscription;
  };

  const fetchUserSubscription = async (userId: string) => {
    try {
      setLoadingSubscription(true);
      
      const firestoreSubscription = await firebaseService.getUserSubscription(userId);
      
      if (firestoreSubscription) {
        // Apply data normalization for subscription data
        const normalizedSubscription = normalizeSubscriptionData(firestoreSubscription);
        
        if (normalizedSubscription.planType === 'basic' && 
            (!normalizedSubscription.currentPeriodEnd || normalizedSubscription.currentPeriodEnd < Date.now() / 1000)) {
          setSubscriptionPlan('basic');
          setSubscriptionStatus('active');
          setCurrentPeriodEnd(null);
          setCancelAtPeriodEnd(false);
          setCanceledAt(null);
          setWillDowngradeTo(null);
        } else {
          if (normalizedSubscription.cancelAtPeriodEnd && 
              normalizedSubscription.currentPeriodEnd && 
              normalizedSubscription.currentPeriodEnd > Date.now() / 1000) {
            setSubscriptionPlan(normalizedSubscription.planType);
          } else {
            setSubscriptionPlan(normalizedSubscription.planType);
          }
          setSubscriptionStatus(normalizedSubscription.status);
          setCurrentPeriodEnd(normalizedSubscription.currentPeriodEnd);
          setCancelAtPeriodEnd(normalizedSubscription.cancelAtPeriodEnd);
          setCanceledAt(normalizedSubscription.canceledAt || null);
          setWillDowngradeTo(normalizedSubscription.willDowngradeTo || null);
        }
        setSubscription({
          planType: normalizedSubscription.planType,
          status: normalizedSubscription.status,
          currentPeriodEnd: normalizedSubscription.currentPeriodEnd,
          cancelAtPeriodEnd: normalizedSubscription.cancelAtPeriodEnd,
          stripeCustomerId: normalizedSubscription.stripeCustomerId,
          stripeSubscriptionId: normalizedSubscription.stripeSubscriptionId,
          updatedAt: normalizedSubscription.updatedAt,
          canceledAt: normalizedSubscription.canceledAt || null,
          willDowngradeTo: normalizedSubscription.willDowngradeTo || null,
          trialEndDate: normalizedSubscription.trialEndDate || null,
        });
      } else {
        setSubscriptionPlan('basic');
        setSubscriptionStatus('active');
        setCurrentPeriodEnd(null);
        setCancelAtPeriodEnd(false);
        setSubscription(null);
        setCanceledAt(null);
        setWillDowngradeTo(null);
      }
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      setSubscriptionPlan('basic');
      setSubscriptionStatus('none');
      setSubscription(null);
      setCanceledAt(null);
      setWillDowngradeTo(null);
    } finally {
      setLoadingSubscription(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserSubscription(currentUser.uid);
      checkTrialEligibility();
    } else {
      setSubscriptionPlan('basic');
      setSubscriptionStatus('none');
      setCurrentPeriodEnd(null);
      setCancelAtPeriodEnd(false);
      setLoadingSubscription(false);
      setSubscription(null);
      setCanceledAt(null);
      setWillDowngradeTo(null);
      setIsTrialEligible(false);
      setTrialEndDate(null);
      setDaysLeftInTrial(null);
    }
  }, [currentUser]);

  const checkTrialEligibility = async () => {
    if (!currentUser) {
      setIsTrialEligible(false);
      return false;
    }

    try {
      const hadProTrial = await stripeService.hasUserHadProTrial(currentUser.uid);
      const isEligible = !hadProTrial;
      setIsTrialEligible(isEligible);
      return isEligible;
    } catch (error) {
      console.error('Error checking trial eligibility:', error);
      setIsTrialEligible(false);
      return false;
    }
  };

  // Neuer Hook, der prüft, ob der Benutzer im Trial ist oder den Pro-Plan hat
  const isInTrialPeriod = () => {
    // Wenn kein Abonnement vorhanden ist, dann ist der Benutzer nicht im Trial
    if (!subscription) return false;
    
    // Es ist ein Trial, wenn es einen trialEndDate gibt und dieser in der Zukunft liegt
    const now = Math.floor(Date.now() / 1000);
    const trialEnd = subscription.trialEndDate;
    
    return trialEnd !== undefined && 
           trialEnd !== null && 
           trialEnd > now && 
           subscription.planType === 'pro';
  };

  // Berechnet die Anzahl der verbleibenden Tage im Trial
  useEffect(() => {
    if (subscription?.trialEndDate !== undefined && subscription?.trialEndDate !== null) {
      const now = Math.floor(Date.now() / 1000);
      if (subscription.trialEndDate > now) {
        const daysLeft = Math.ceil((subscription.trialEndDate - now) / (60 * 60 * 24));
        setDaysLeftInTrial(daysLeft);
        setTrialEndDate(subscription.trialEndDate);
      } else {
        setDaysLeftInTrial(null);
        setTrialEndDate(null);
      }
    } else {
      setDaysLeftInTrial(null);
      setTrialEndDate(null);
    }
  }, [subscription]);

  const isSubscriptionActive = () => {
    // For trial subscriptions, they are active if the trial period is not expired
    if (subscriptionStatus === 'trialing' && trialEndDate) {
      return trialEndDate > Date.now() / 1000;
    }

    return (
      (subscriptionStatus === 'active') &&
      !cancelAtPeriodEnd &&
      (currentPeriodEnd === null || currentPeriodEnd > Date.now() / 1000)
    );
  };

  const isTrialActive = () => {
    // Es ist ein Trial, wenn es einen trialEndDate gibt und dieser in der Zukunft liegt
    const now = Math.floor(Date.now() / 1000);
    const trialEnd = subscription?.trialEndDate;
    
    return trialEnd !== undefined && 
           trialEnd !== null && 
           trialEnd > now;
  };

  const hasPaidSubscription = () => {
    // Check if there's an active trial
    if (isTrialActive()) {
      return true;
    }
    
    // Otherwise check for a paid subscription
    return (subscriptionPlan === 'pro' || subscriptionPlan === 'enterprise') && 
           (subscriptionStatus === 'active' || hasActiveOrPendingCancellationSubscription());
  };

  const hasActiveOrPendingCancellationSubscription = () => {
    // Paid subscription with active period
    if (subscriptionStatus === 'active' && currentPeriodEnd && currentPeriodEnd > Date.now() / 1000) {
      return true;
    }
    
    // Check for trial validity
    if (subscriptionStatus === 'trialing' && trialEndDate) {
      return trialEndDate > Date.now() / 1000;
    }
    
    // For cancelled subscriptions that haven't expired yet
    return (
      subscriptionStatus === 'active' &&
      cancelAtPeriodEnd === true &&
      currentPeriodEnd !== null &&
      currentPeriodEnd > Date.now() / 1000
    );
  };

  const canAccessProFeatures = () => {
    // Für aktive Trial-Abonnements
    if (isTrialActive()) {
      return true;
    }
    
    // Für gekündigte Trial-Abonnements, die noch nicht abgelaufen sind
    if (subscriptionStatus === 'trialing' && 
        cancelAtPeriodEnd && 
        subscription?.trialEndDate && 
        subscription.trialEndDate > Date.now() / 1000) {
      return true;
    }
    
    // Spezialfall für Trial-Abos, sicherstellen dass der Status korrekt erkannt wird
    if (subscriptionPlan === 'pro' && subscriptionStatus === 'trialing') {
      return true;
    }
    
    // Regular pro features access check
    return hasActiveOrPendingCancellationSubscription() && 
           (subscriptionPlan === 'pro' || subscriptionPlan === 'enterprise');
  };

  const canAccessEnterpriseFeatures = () => {
    // For trial subscriptions, we need to check if this was an enterprise trial
    // Since we're normalizing the planType already, we can just check it
    if (isTrialActive() && subscriptionPlan === 'enterprise') {
      return true;
    }
    
    // Regular enterprise features access check
    return hasActiveOrPendingCancellationSubscription() && subscriptionPlan === 'enterprise';
  };

  const redirectToCheckout = async (planType: 'pro' | 'enterprise', isTrial: boolean = false) => {
    if (!currentUser) {
      console.error('User must be logged in to checkout');
      throw new Error('You must be logged in to upgrade your subscription');
    }

    try {
      console.log(`SubscriptionContext: Redirecting to checkout for ${planType} plan, trial: ${isTrial}`);
      
      if (isTrial && !isTrialEligible) {
        throw new Error('You have already used your free trial period');
      }
      
      const priceId = planType === 'pro' 
        ? stripeService.getProPlanPriceId() 
        : stripeService.getEnterprisePlanPriceId();
      console.log(`Price ID for ${planType}: ${priceId}`);
      
      await stripeService.createCheckoutSession({
        priceId,
        userId: currentUser.uid,
        userEmail: currentUser.email || '',
        isTrial: isTrial,
      });
    } catch (error) {
      console.error('Error in SubscriptionContext redirectToCheckout:', error);
      throw error;
    }
  };

  const redirectToCustomerPortal = async () => {
    if (!currentUser) {
      console.error('User must be logged in to access customer portal');
      return;
    }

    try {
      const portalUrl = await stripeService.createPortalSession(currentUser.uid);
      window.location.href = portalUrl;
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
    }
  };

  const renewSubscription = async () => {
    if (!currentUser) {
      console.error('User must be logged in to renew subscription');
      return;
    }

    try {
      await stripeService.renewSubscription(currentUser.uid);
    } catch (error) {
      console.error('Error renewing subscription:', error);
      throw error;
    }
  };

  useEffect(() => {
    const handleSuccessfulCheckout = async () => {
      const params = new URLSearchParams(window.location.search);
      const sessionId = params.get('session_id');
      const isTrial = params.get('trial') === 'true';
      
      // Wenn wir von einem erfolgreichen Checkout zurückkehren und es ein Trial war
      if (sessionId && isTrial && currentUser) {
        console.log('Successful trial checkout detected, marking user as having used trial');
        try {
          // Jetzt erst markieren wir den Benutzer als Trial-Nutzer
          await firebaseService.markUserStartedProTrial(currentUser.uid);
          // Aktualisieren Sie die Trial-Berechtigung
          await checkTrialEligibility();
        } catch (error) {
          console.error('Error marking user as trial user:', error);
        }
      }
    };

    handleSuccessfulCheckout();
  }, [window.location.search, currentUser]);

  const value = {
    subscriptionPlan,
    subscriptionStatus,
    currentPeriodEnd,
    cancelAtPeriodEnd,
    loadingSubscription,
    canceledAt,
    willDowngradeTo,
    isSubscriptionActive,
    hasPaidSubscription,
    canAccessProFeatures,
    canAccessEnterpriseFeatures,
    redirectToCheckout,
    redirectToCustomerPortal,
    renewSubscription,
    hasActiveOrPendingCancellationSubscription,
    fetchUserSubscription,
    isSubscriptionInfoLoaded: !loadingSubscription,
    subscription,
    isTrialEligible,
    checkTrialEligibility,
    isInTrialPeriod,
    trialEndDate,
    daysLeftInTrial
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}
