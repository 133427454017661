import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  UserCredential,
  AuthError,
  sendEmailVerification,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { firebaseService } from '../services/firebaseService';
import { useAccountStore } from '../store/accountStore';
import { useTradeStore } from '../store/tradeStore';
import { useUserLevelStore } from '../store/userLevelStore';
import { migrateHabitsCollections } from '../utils/habitsMigration';
import { mt5SyncService } from '../services/mt5SyncService';

interface AuthContextType {
  currentUser: User | null;
  signup: (email: string, password: string) => Promise<User>;
  login: (email: string, password: string) => Promise<boolean>;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  checkEmailVerification: () => Promise<boolean>;
  clearError: () => void;
  loading: boolean;
  error: string | null;
  initialAuthCheckComplete: boolean;
  isEmailVerified: boolean;
}

// Create context with default values
const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isEmailVerified: false,
  loading: true,
  error: null,
  initialAuthCheckComplete: false,
  signup: async (_email: string, _password: string) => { 
    console.error("Default signup function called - should never happen"); 
    return null as unknown as User; 
  },
  login: async () => { console.error("Default login function called - should never happen"); return false; },
  loginWithGoogle: async () => {},
  logout: async () => {},
  sendVerificationEmail: async () => {},
  checkEmailVerification: async () => false,
  clearError: () => {},
});

// Set auth persistence to LOCAL to survive browser refreshes
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initialAuthCheckComplete, setInitialAuthCheckComplete] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { setAccounts, resetStore: resetAccountStore } = useAccountStore();
  const { setTrades, resetStore: resetTradeStore } = useTradeStore();
  const { loadUserLevel, resetStore: resetUserLevelStore } = useUserLevelStore();

  async function loadUserData(userId: string) {
    try {
      // Load user data (accounts)
      const userData = await firebaseService.getUserData(userId);
      if (userData) {
        setAccounts(userData.accounts || []);
      }

      // Load trades
      const trades = await firebaseService.getUserTrades(userId);
      setTrades(trades || []);
      
      // Load user level data
      await loadUserLevel(userId);
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  }

  async function signup(email: string, password: string) {
    try {
      setError(null);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Set the current user immediately after signup
      setCurrentUser(userCredential.user);
      setIsEmailVerified(userCredential.user.emailVerified);
      
      // Initialize user data in Firestore
      await firebaseService.createUserData(userCredential.user.uid, {});
      await loadUserData(userCredential.user.uid);
      
      // Send email verification with retry mechanism
      try {
        await sendEmailVerification(userCredential.user);
        console.log("Verification email sent successfully");
      } catch (emailError) {
        console.error("Error sending verification email:", emailError);
        // We don't want to fail the signup process if email sending fails
        // This will be handled in the EmailVerification component where the user can retry
      }
      
      // Return the user credential so the calling code can verify successful authentication
      return userCredential.user;
    } catch (error) {
      console.error('Error during signup:', error);
      setError((error as AuthError).message || 'Failed to sign up');
      throw error;
    }
  }

  async function login(email: string, password: string) {
    try {
      setError(null);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await loadUserData(userCredential.user.uid);
      return true; // Return success
    } catch (error) {
      console.error('Error during login:', error);
      
      // User-friendly error message in English
      const errorCode = (error as AuthError).code;
      if (errorCode === 'auth/invalid-credential' || 
          errorCode === 'auth/wrong-password' || 
          errorCode === 'auth/user-not-found') {
        setError('Email or password incorrect. Please check your credentials.');
      } else if (errorCode === 'auth/too-many-requests') {
        setError('Too many failed login attempts. Please try again later.');
      } else {
        setError('Login failed. Please try again.');
      }
      
      return false; // Return failure without throwing the error
    }
  }

  async function loginWithGoogle() {
    try {
      setError(null);
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      
      // Set the current user immediately
      setCurrentUser(userCredential.user);
      setIsEmailVerified(userCredential.user.emailVerified);
      
      // For new Google users, initialize their data
      // Since we can't reliably detect if this is a new user through additionalUserInfo,
      // we'll check if user data exists in Firestore
      try {
        const userData = await firebaseService.getUserData(userCredential.user.uid);
        if (!userData) {
          // Initialize user data for new Google users
          await firebaseService.createUserData(userCredential.user.uid, {});
        }
      } catch (error) {
        // If there's an error fetching user data, assume it's a new user
        await firebaseService.createUserData(userCredential.user.uid, {});
      }
      
      // Load user data
      await loadUserData(userCredential.user.uid);
    } catch (error) {
      console.error('Error during Google login:', error);
      setError((error as AuthError).message || 'Failed to log in with Google');
      throw error;
    }
  }

  async function logout() {
    try {
      await signOut(auth);
      resetAccountStore();
      resetTradeStore();
      resetUserLevelStore();
    } catch (error) {
      console.error('Error during logout:', error);
      setError((error as AuthError).message || 'Failed to log out');
      throw error;
    }
  }

  async function sendVerificationEmail() {
    try {
      if (!currentUser) {
        console.error('No user is logged in to send verification email');
        return Promise.reject(new Error('No user is logged in'));
      }

      if (currentUser.emailVerified) {
        console.log('Email is already verified');
        return Promise.resolve();
      }

      // Always reload the user to ensure we have the latest state
      await currentUser.reload();
      
      // Send the verification email with the correct redirect URL
      await sendEmailVerification(currentUser, {
        url: window.location.origin + '/verify-email' // Changed from '/auth' to the correct route
      });
      
      console.log('Verification email sent successfully');
      return Promise.resolve();
    } catch (error) {
      console.error('Error sending verification email:', error);
      const errorMessage = (error as AuthError).message || 'Failed to send verification email';
      setError(errorMessage);
      
      // Check for specific Firebase error codes that might indicate an issue
      const firebaseError = error as AuthError;
      if (firebaseError.code === 'auth/too-many-requests') {
        return Promise.reject(new Error('Too many attempts. Please try again later.'));
      }
      
      throw error;
    }
  }

  async function checkEmailVerification(): Promise<boolean> {
    try {
      if (currentUser) {
        // Force reload the user to get the latest emailVerified status
        await currentUser.reload();
        console.log("Email verification status after reload:", currentUser.emailVerified);
        
        // Update the state with the latest verification status
        const isVerified = currentUser.emailVerified;
        setIsEmailVerified(isVerified);
        
        return isVerified;
      }
      return false;
    } catch (error) {
      console.error('Error checking email verification:', error);
      return false;
    }
  }

  // Function to clear error messages
  function clearError() {
    setError(null);
  }

  useEffect(() => {
    if (currentUser) {
      const runMigrations = async () => {
        await migrateHabitsCollections(currentUser.uid);
      };
      runMigrations();
    }
  }, [currentUser]);

  useEffect(() => {
    console.log("Setting up auth state listener");
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth state changed:", user ? `User ${user.uid} (verified: ${user.emailVerified})` : "No user");
      
      if (user) {
        // Only reload the user if we need to check verification status
        // This prevents extra reloads when navigating within the app
        if (currentUser?.uid === user.uid && currentUser.emailVerified === user.emailVerified) {
          console.log("User already in state with same verification status, skipping reload");
        } else {
          // Reload user to get fresh email verification status
          await user.reload();
          setCurrentUser(user);
          setIsEmailVerified(user.emailVerified);
          
          // Load user data from Firestore
          try {
            await loadUserData(user.uid);
            
            // Initialize MT5 sync service for authenticated users
            if (user.emailVerified) {
              console.log("Initializing MT5 sync service for verified user");
              mt5SyncService.initialize();
            }
          } catch (error) {
            console.error('Error loading user data:', error);
          }
        }
      } else {
        setCurrentUser(null);
        setIsEmailVerified(false);
        setAccounts([]);
        
        // Shutdown MT5 sync service when user logs out
        console.log("Shutting down MT5 sync service");
        mt5SyncService.shutdown();
      }
      
      setLoading(false);
      setInitialAuthCheckComplete(true);
    });

    return () => {
      unsubscribe();
      // Ensure the sync service is shut down when the component unmounts
      mt5SyncService.shutdown();
    };
  }, [currentUser]);

  const value = {
    currentUser,
    signup,
    login,
    loginWithGoogle,
    logout,
    sendVerificationEmail,
    checkEmailVerification,
    clearError,
    loading,
    error,
    initialAuthCheckComplete,
    isEmailVerified
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
